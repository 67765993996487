import styled, { css } from 'styled-components';
import { Icon } from '@vezeeta/web-components';

export const HelpIcon = styled(Icon)`
  cursor: pointer;
  transform: ${props => (props.language === 'en' ? 'unset' : 'scale(-1, 1)')};
`;

export const extendContentContainer = css`
  ${props => props.theme.media.tablet`
    padding: 0px;
  `};
  ${props => props.theme.media.phone`
    padding: 0px;
  `};
`;
