export const getFeatureFlags = () => ({});

export const isSupportSingleLanguage = (user, isUserLoaded) => {
  // Get user token
  if (isUserLoaded) {
    // eslint-disable-next-line prefer-destructuring
    const countriesSupportSingleLanguage = window.countriesSupportSingleLanguage;
    return countriesSupportSingleLanguage.includes(user.CountryId);
  }
  return true;
};

export const getbreadCrumbSteps = localization => {
  const breadcrumbSteps = [
    [
      {
        name: localization && localization.finance,
        pathname: '/',
      },
    ],
  ];

  const breadcrumbProps = {
    stepsArray: breadcrumbSteps,
    validationElementName: '#user-form',
  };

  return breadcrumbProps;
};
